import { SignupFormValues, SignupSuccessResponse } from "./types";
import api, { handleResponse } from "../../../services/api";
import { apiPaths } from "../../../utils/constants";
import * as ActionType from "./actions";
import { ACCOUNTS_HOSTNAME } from "../../../utils/constants";

const registerNewUser = (
  values: SignupFormValues,
  plan: string | string[] | null,
  dispatch: any,
): void => {
  dispatch({
    type: ActionType.REGISTRATION_INPROGRESS,
    payload: null,
  });

  api
    .postAccounts<SignupSuccessResponse, SignupFormValues>(apiPaths.signup, values, {
      params: { plan: plan },
    })
    .then(response => {
      const action = handleResponse<SignupSuccessResponse>(
        response,
        ActionType.REGISTRATION_SUCCESS,
        ActionType.REGISTRATION_FAILURE
      );

      dispatch(action);

      if (action.type === ActionType.REGISTRATION_SUCCESS) {
        window.location.href = `${ACCOUNTS_HOSTNAME}/signup_success/?email=${values.user.email}`;
      }
    })
    .catch(err => {
      const action = handleResponse<{}>(
        err.response,
        ActionType.REGISTRATION_SUCCESS,
        ActionType.REGISTRATION_FAILURE
      );
      dispatch(action);
    });
};

const clearAlert = (dispatch: any): void => {
  dispatch({
    type: ActionType.CLEAR_USER_MESSAGE,
    payload: null,
  });
};

export { registerNewUser, clearAlert };
