import React, { ChangeEvent } from "react";
import "./style.css";

interface PropType {
  name: string;
  disable?: boolean;
  checked?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox: React.FunctionComponent<PropType> = (props: PropType) => {
  const { name, disable, checked, onChange } = props;

  let divClass;
  let inputClass;
  const selectClass = (): void => {
    if (checked && disable) {
      divClass = 'hover:bg-transparent';
      inputClass = 'bg-background-silver-sand';
    } else if (checked) {
      divClass = 'hover:bg-celtic-blue-light-tint-2';
      inputClass = 'bg-celtic-blue-primary';
    } else if (disable) {
      divClass = '';
      inputClass = '';
    } else {
      divClass = 'opacity';
      inputClass = '';
    }
  };
  selectClass();
  return (
    <>
      <div className={`${divClass} relative w-12 h-12 rounded-full`}>
        <input
          name={name}
          type="checkbox"
          className={`${inputClass} checkbox absolute appearance-none focus:outline-none border-2 rounded-sm border-solid border-background-silver-sand`}
          onChange={onChange}
          disabled={disable}
          checked={checked}
          data-testid={name}
        />
        <i
          className={`${
            checked ? 'text-white' : 'text-transparent'
          } tick`}
        />
      </div>
    </>
  );
};
Checkbox.defaultProps = {
  disable: false,
  checked: false,
};
export default Checkbox;
