import * as Yup from "yup";
import regex from "../../../utils/regex";

// eslint-disable-next-line no-useless-escape

const signupSchema = Yup.object({
  email: Yup.string()
    .required('Required')
    .email('Invalid email address'),
  password: Yup.string()
    .required('Required')
    .matches(
      regex.passRegex,
      'Your password must be at least 8 characters long, should contain number, special character, uppercase and lowercase letters.'
    ),
});
export default signupSchema;
