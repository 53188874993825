import { useReducer } from "react";
import {
  SignupState,
  SignupFailureResponse,
  SignupSuccessResponse,
} from "./types";
import * as ActionType from "./actions";

const initialState: SignupState = {
  user: {} as SignupSuccessResponse,
  error: {} as SignupFailureResponse,
  isLoading: false,
};

const signupReducer = (
  state: SignupState,
  action: {
    type: string;
    payload: any;
  }
): SignupState => {
  switch (action.type) {
    case ActionType.REGISTRATION_SUCCESS:
      return {
        user: action.payload,
        error: initialState.error,
        isLoading: false,
      };
    case ActionType.REGISTRATION_FAILURE:
      return {
        user: initialState.user,
        error: action.payload.error,
        isLoading: false,
      };
    case ActionType.REGISTRATION_INPROGRESS:
      return {
        ...state,
        isLoading: true,
      };
    case ActionType.CLEAR_USER_MESSAGE:
      return {
        ...state,
        user: { ...state.user, message: '' },
        error: initialState.error,
      };
    default:
      throw new Error();
  }
};

const useSignup = () => {
  const [signupState, dispatch] = useReducer(signupReducer, initialState);
  return {
    signupState,
    dispatch,
  };
};

export { initialState, useSignup };
