import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import Button from "../components/common/Button";
import TextInput from "../components/common/TextInput";
import Checkbox from "../components/common/Checkbox";
import { PAYOUTS_HOSTNAME } from "../utils/constants";
import Box from "../images/box.svg";
import { useSignup } from "../components/home/LandingAdPageBulkPayout/store";
import {
  registerNewUser,
  clearAlert,
} from "../components/home/LandingAdPageBulkPayout/services";
import Alert from "../components/common/Alert";
import signupSchema from "../components/home/LandingAdPageBulkPayout/validationSchema";
import Header from "../components/home/Header";
import Modal from "../components/common/Modal";
import ContactUs from "../components/home/ContactUs";
import "../components/home/LandingAdPageBulkPayout/style.css";
// import PricingModel from "../components/home/PricingModel";
// import Footer from "../components/home/Footer";

const LandingAddPageBulkPayout: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showContactUs, setShowContactUs] = useState(false);
  const [checked, setChecked] = useState(false);
  const { signupState, dispatch } = useSignup();

  const { error, user, isLoading } = signupState;

  const togglePassword = (): void => {
    setShowPassword(!showPassword);
  };

  const openTerms = (): void => {
    window.open(`${PAYOUTS_HOSTNAME}/terms`, "_blank");
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: signupSchema,
    onSubmit: (values) => {
      const user = {
        user: {
          email: values.email,
          password: values.password,
          t_and_c_accepted: checked,
        },
      };
      if (checked) {
        registerNewUser(user, "", dispatch);
      }
    },
  });

  const openModal = (): void => setShowContactUs(true);
  const closeModal = (): void => {
    setShowContactUs(false);
  };

  return (
    <>
      <Header openModal={openModal} />
      <section className="bg-white w-full h-screen">
        <div className="lg:pt-24 pt-36 landing-wrapper">
          <div className="container mx-auto flex flex-wrap flex-row md:flex-row justify-between items-center h-full relative">
            <div className="flex flex-col w-full lg:w-6/12 justify-center items-start text-left px-6 lg:pl-0 lg:pr-24 h-full py-4">
              <h1 className="my-4 text-6xl pt-10 lg:pt-0 leading-tight text-black">
                {t("home.landingAdPagePayout.title")}
              </h1>
              <p className="leading-normal text-neutral-oxford-blue w-11/12">
                {t("home.landingAdPagePayout.subTitle")}
              </p>

              <div className="relative flex flex-col signup-card rounded mt-6 break-words w-10/12 mb-5">
                <div className="justify-start pt-0 w-full">
                  {!!error.reason && (
                    <Alert
                      alertType="error"
                      title={t(
                        `home.errorResponseType.${error.type}.${error.reason}`
                      )}
                    />
                  )}
                  {!error.reason && !!error.message && (
                    <Alert alertType="error" title={error.message} />
                  )}
                  
                  {!!user.message && (
                    <Alert alertType="success" title={t(
                      `home.signup.signupSuccess.${user.message}`
                    )} />
                  )}

                  <form onSubmit={formik.handleSubmit}>
                    <div className="relative w-full mb-6">
                      <TextInput
                        name="email"
                        type="email"
                        className="trans"
                        placeHolder={t("home.signup.placeholder.email")}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.errors.email}
                      />
                    </div>

                    <div className="relative w-full mb-4">
                      <TextInput
                        name="password"
                        className="trans"
                        placeHolder={t("home.signup.placeholder.password")}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.errors.password}
                        icon={showPassword ? "fa-eye" : "fa-eye-slash"}
                        type={showPassword ? "text" : "password"}
                        handleIconClick={togglePassword}
                      />
                    </div>
                    <div className="flex content-start mb-6">
                      <div>
                        <Checkbox
                          name="check"
                          onChange={() => {
                            setChecked(!checked);
                          }}
                          checked={checked}
                          disable={false}
                        />
                      </div>
                      <span className="body-text-2 text-left py-3 inline-block">
                        {t("home.signup.termsPrivacy.message.0")}{" "}
                        <button
                          type="button"
                          onClick={openTerms}
                          className="text-celtic-blue-primary"
                        >
                          {t("home.signup.termsPrivacy.termsLink")}
                        </button>{" "}
                        {t("home.signup.termsPrivacy.message.1")}{" "}
                        <button
                          type="button"
                          onClick={openTerms}
                          className="text-celtic-blue-primary"
                        >
                          {" "}
                          {t("home.signup.termsPrivacy.privacyLink")}
                        </button>{" "}
                        {t("home.signup.termsPrivacy.message.2")}
                      </span>
                    </div>

                    <Button
                      isLoading={isLoading}
                      btnText={t("home.signup.submitButton")}
                      btnStyle={checked ? "primary" : "disabled"}
                      btnType="submit"
                      className="w-full"
                    />
                  </form>
                </div>
              </div>
            </div>
            <div className="w-full md:w-6/12 hidden lg:block flex flex-wrap justify-center relative h-full">
              <div className="w-full md:mx-40 md:my-36">
                <Box />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <PricingModel /> */}
      {/* <Footer openModal={openModal} /> */}
      <Modal
        content={<ContactUs closeModal={closeModal} />}
        show={showContactUs}
        closeModal={closeModal}
      />
    </>
  );
};

export default LandingAddPageBulkPayout;
